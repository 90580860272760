<template>
  <div class="collection-card-skeleton">
    <div
      class="
        collection-card-img
        flex flex-col
        justify-center
        h-full
        items-center
      "
    >
      <div class="title lora-bold my-4"></div>
      <div class="description rounded my-1 w-48"></div>
      <div class="description rounded my-1 w-28"></div>
      <div class="button lora-bold my-4"></div>
    </div>
  </div>
</template>
